<template>
  <div id="image-carousel">
    <v-carousel
      v-model="carousel"
      :height="imageHeight"
      hide-delimiter-background
      class="rounded-lg"
    >
      <v-carousel-item
        v-for="(image, i) in images"
        :key="i"
      >
        <v-img
          :height="imageHeight"
          :src="image.url"
          @click="carouselClick"
          class="cursor-pointer"
        ></v-img>
      </v-carousel-item>
    </v-carousel>

    <!-- Modal For Product Images -->
    <Modal
      :dialog="fullscreen"
      @close="closeModal"
      fullscreen
      title=" "
    >
      <template v-slot:content>
        <v-carousel
          v-model="carousel"
          hide-delimiter-background
          :height="windowHeight * 0.8"
          class="rounded-lg mt-10"
        >
          <v-carousel-item
            v-for="(image, i) in images"
            :key="i"
          >
            <v-img
              :src="image.url"
              :height="windowHeight * 0.8"
              contain
            ></v-img>
          </v-carousel-item>
        </v-carousel>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Shared/Modal.vue';

export default {
  name: 'ImageCarousel',
  components: {
    Modal,
  },
  props: {
    images: {
      type: Array,
      required: true,
    },
    fullscreen: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      carousel: 0,
      windowHeight: window.innerHeight,
    };
  },
  computed: {
    imageHeight() {
      return this.$vuetify.breakpoint.smAndDown ? 250 : this.fullscreen ? 700 : 456;
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    carouselClick() {
      this.$emit('setFullscreen', true);
    },
    closeModal() {
      this.$emit('setFullscreen', false);
    },
    handleResize() {
      this.windowHeight = window.innerHeight;
    },
  },
};
</script>
